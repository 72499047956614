<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The results of a set of measurements (in weight %) are:
        <br />
        <number-value :value="X1" />
        <br />
        <number-value :value="X2" />
        <br />
        <number-value :value="X3" />
        <br />
        <number-value :value="X4" />
        <br />
        <number-value :value="X5" />
      </p>

      <p class="mb-2">
        For the above set of data, calculate the mean, the standard deviation, the relative standard
        deviation, the coefficient of variation, and the spread.
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-5"
        prepend-text="$\text{Mean}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{Standard Deviation}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input3"
        class="mb-5"
        prepend-text="$\text{Relative standard deviation}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input4"
        class="mb-5"
        prepend-text="$\text{Coefficient of variation}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input5"
        class="mb-5"
        prepend-text="$\text{Spread}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A1Q1',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
  computed: {
    X1() {
      return this.taskState.getValueBySymbol('X1').content;
    },
    X2() {
      return this.taskState.getValueBySymbol('X2').content;
    },
    X3() {
      return this.taskState.getValueBySymbol('X3').content;
    },
    X4() {
      return this.taskState.getValueBySymbol('X4').content;
    },
    X5() {
      return this.taskState.getValueBySymbol('X5').content;
    },
  },
};
</script>
